//import angular from 'angular';


class EquipmentUrls {
    public readonly readingIds = {
        //select (select name from referencedata where id = referencedataid) + ': "' + lower(convert(varchar(40), id)) + '",'  from readingeventtype
        DISTANCE: "acd93fc4-c632-446b-b56f-833255a6c3b5",
        WHEELALIGN: "35d63e22-c2d6-4874-b67d-4f8425afdfef",
        TEMPERATURE: "2a05c8e9-7938-4f9b-ad64-14564180bf20",
        PRESSURE: "0cf3202c-f8e0-4898-af67-af4c66580455",
        LifeOTD: "d69a30be-9367-430d-93f7-b07ad551b869",
        NITROGEN: "68dc58ac-07cc-494e-bd6a-b4240f2f8106",
        PrimaryOTD: "23712b0d-2cdc-4b43-a008-247bf8a932b2",
        HOURS: "ccef04cc-314a-4216-9e8c-cf95687d90ee",
        CHAINDEPTH: "b4b183bf-09cd-4e5a-bf23-1eab58efebd0",
        SNOWFALL: "209cafdf-04b0-47cd-80d5-6e8cba9542ba",
        TREADDEPTH: "57dc1176-4a85-474b-93d0-1c2ba992eb16",
        LUBRICATEDSTUDS: "0ccac1f3-3414-4cdf-bf60-3305052174c7",
        RAINFALL: "f68daf17-1248-4c30-8ed0-055c0b9747a9",
        TORQUE: "3c3ecca7-5765-4ab4-aa46-0f258c655960",
        TREADTEMP: "52809e0a-e69c-49a7-8fc8-dabe77c6cbc4",
        NDT: "069f0e0f-1929-46c5-a29d-e66fc00f3943"
    }

    public readonly url = {
        // history
        getHistory: baseUrl + 'api/equipment/common/getHistory',
        getChartData: 'equipment/common/getChartData',
        getReadingTypeNames: baseApiUrl + 'equipment/common/getReadingTypeNames',
        getEventTypeNames: baseApiUrl + 'equipment/common/getEventTypeNames',
        getEventUsers: baseApiUrl + 'equipment/common/getEventUsers',
        removeComponents: baseApiUrl + 'equipment/common/removeComponents',
        historyExport: 'equipment/common/exportHistory',
        getFitmentHistory: 'equipment/common/getFitmentHistory',

        serialNumberList: 'equipment/common/serialNumberList',
        quickSearch: 'equipment/common/quickSearch',

        // attachments
        getAttachment: 'equipment/common/getAttachment',
        deleteAttachment: 'equipment/common/deleteAttachment',
        getAttachments: baseApiUrl + 'equipment/common/getAttachments',
        saveAttachment: 'equipment/common/saveAttachment',
        attachmentsExport: 'equipment/common/attachmentsExport',

        // comments
        getComment: 'equipment/common/getComment',
        commentsExport: 'equipment/common/commentsExport',
        deleteComment: 'equipment/common/deleteComment',
        getComments: baseApiUrl + 'equipment/common/getComments',
        saveComment: 'equipment/common/saveComment',

        //fleets
        getFleets: baseApiUrl + 'equipment/common/getFleets',

        getEquipmentTypes: 'api/equipment/common/getEquipmentTypes',
        getFittedComponentTypes: baseApiUrl + 'equipment/common/getFittedComponentTypes',

        checkEquipmentExists: 'equipment/common/checkEquipmentExists'
    };
}

angular.module('app').service('equipmentUrls', EquipmentUrls);